import css from './Why.module.css'
import img1 from './img/img1.png'
import img2 from './img/img2.png'
import img3 from './img/img3.png'
import arrow from './img/arrow.png'
import {motion} from 'framer-motion'

const Why = () => {
  return (
<section className={css.container} id='why'>
<img src='/bac4.png' className='bac4'/>
    <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.headerContainer}><h2 className={css.header}>Как это работает?</h2></motion.div>
    <div className={css.cardContainer}>
        <div className={css.card} id='why1'>
            <img className={css.img} src={img1} />
            <h2 className={css.cardHeader}>Заключаем сделку</h2>
        </div>
        <img src={arrow} className={css.arrow}/>
        <div className={css.card} id='why2'>
            <img className={css.img} src={img2} />
            <h2 className={css.cardHeader}>Новые клиенты видят лучший рейтинг</h2>
        </div>
        <img src={arrow} className={css.arrow}/>
        <div className={css.card} id='why3'>
            <img className={css.img} src={img3} />
            <h2 className={css.cardHeader}>Продажи растут</h2>
        </div>
    </div>
</section>
  )
}

export default Why