import css from './Contacts.module.css'
import whatsapp from './img/whatsapp.png'
import telegram from './img/telegram.png'
import { useEffect } from 'react';
const Contacts = ({setModal}) => {
  useEffect(() => {
    console.log(window.location.href)
    if (window.location.href.includes('contact')) {
      setModal(true);
    }
  }, [setModal]);
  return (
<section className={css.container} id='contacts'>
    <div className={css.modalBefore}>
        <div className={css.modalWind}>
            <h2 className={css.header}>Свяжитесь с нами чтобы начать работу</h2>
            <div className={css.buttonContainer}>
              <div className={css.rowContainer}>
                <a href='https://wa.me/79300369460'><img  src={whatsapp} className={css.icon}/></a>
                <a href="https://t.me/+79300369460"><img  src={telegram} className={css.icon}/></a>
              </div>

                <a  href="mailto:admgeosila@gmail.com"className={css.button}>Написать на почту</a>
                <a  href="tel:+79300369460" className={css.button}>Позвонить</a>
                <button className={css.button} onClick={()=>setModal(true)}>Оставить заявку</button>
            </div>
        </div>
    </div>

</section>
  )
}

export default Contacts