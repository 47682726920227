import css from './Commit.module.css'
import img from './img/img.png'




const Commit = () => {
  return (
<section className={css.container}>
    <img src='/bac3.png' className='bac3'/>
    <div className={css.headerContainer}><h2 className={css.header}>Долгосрочные отношения</h2></div>
    <div className={css.rowContaiener}>
        <div className={css.textContainer}>
          <ul className={css.ul}>
            <li className={css.li}>Заключаем договор, в котором указаны 100% гарантии с нашей стороны</li>
            <li className={css.li}>Поднимаем рейтинг на яндекс картах</li>
            <li className={css.li}>Видите усиление потока клиентов</li>
            <li className={css.li}>Работаем над продвижением на других геоплатформах</li>
            <li className={css.li}>Видите повышение платежеспособности клиентов</li>
            <li className={css.li}>Создаем сайт или продвигаем уже имеющийся</li>
            <li className={css.li}>Количество заказов растет</li>
          </ul>
        </div>
        <div className={css.imgContainer}>
            <img src={img} className={css.img} />
        </div>
    </div>
</section>
  )
}

export default Commit