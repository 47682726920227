import {Home} from './pages/home'
import {Confidential} from './pages/privatepolicy'
import {Route, Routes, Navigate} from 'react-router-dom'

function App() {
  
  return (
<Routes>
    <Route index path='/' element={<Home/>}/>
    <Route index path='/buy' element={<Home/>}/>
    <Route index path='/contact' element={<Home/>}/>
    <Route index path='/privatepolicy' element={<Confidential/>}/>
</Routes>

  );
}

export default App;
