import css from './Service.module.css'
import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import {motion} from 'framer-motion'

const Service = () => {
  return (
<section className={css.container} id='service'>
    <img src='/bac1.png' className='bac1'/>
    <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.headerContainer}><h2 className={css.header}>Наши услуги</h2></motion.div>
    <div className={css.cardContainer}>
        <motion.div className={css.card} id='service1'>
            <img className={css.img} src={img1} />
            <h2 className={css.cardHeader}>Повышение рейтинга</h2>
        </motion.div>
        <div className={css.card} id='service2'>
            <img className={css.img} src={img2} />
            <h2 className={css.cardHeader}>Продвигаем на всех геосервисах</h2>
        </div>
        <div className={css.card} id='service3'>
            <img className={css.img} src={img3} />
            <h2 className={css.cardHeader}>Создаем и развиваем сайт</h2>
        </div>
    </div>
</section>
  )
}

export default Service