import css from './Result.module.css'
import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import {motion} from 'framer-motion'

const Result = () => {
  return (
<section className={css.container} id='result'>
    <img src='/bac2.png' className='bac2'/>
    <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.headerContainer}><h2 className={css.header}>Ожидаемые результаты</h2></motion.div>
    <div className={css.cardContainer}>
        <div className={css.card} id='result1'>
            <img className={css.img} src={img1} />
            <h2 className={css.cardHeader}>Повышение клиентопотока</h2>
        </div>
        <div className={css.card} id='result2'>
            <img className={css.img} src={img2} />
            <h2 className={css.cardHeader}>Рейтинг остается на картах</h2>
        </div>
        <div className={css.card} id='result3'>
            <img className={css.img} src={img3} />
            <h2 className={css.cardHeader}>Повышение платежеспособности клиентов</h2>
        </div>
    </div>
</section>
  )
}

export default Result