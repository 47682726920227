import { useEffect, useState } from 'react';
import css from './Modal.module.css'
import InputMask from 'react-input-mask';
import {motion} from 'framer-motion'
import axios from 'axios';
import emailjs from 'emailjs-com';
import mark from './img/mark.png'
import { Link } from 'react-router-dom';




// Данные для отправки в телеграмм
const token = process.env.REACT_APP_TOKEN
const chatId = process.env.REACT_APP_CHAT_ID
const URI_API = `https://api.telegram.org/bot${ token }/sendMessage`


const Modal = ({setModal}) => {
    const [check, setCheck] = useState(false)
    const [number, setNumber] = useState(localStorage.getItem('number') || '');
    const [name, setName] = useState(localStorage.getItem('name') || '');
    const [email, setEmail] = useState(localStorage.getItem('email') || '');
    const [error,setError] = useState(false)
    let message = `<b>Заявка с сайта!</b>\n`
    message += `<b>Отправитель: ${name}</b>\n`
    message += `<b>Номер: ${number}</b>\n`
    message += `<b>email: ${email}</b>\n`

    // const templateParams = {
    //     name: name,
    //     email: 'yoricktomson@gmail.com',
    //     mail:email,
    //     number: number
    // };
    useEffect(()=>{
        if(window.screen.width<=768){
            document.body.classList.add('popup-open');
        }
        return ()=>{
            document.body.classList.remove('popup-open');
        }
    },[])
    const click = async() => {
        
        if(!name || !email || !number || !check){
            setError(true)
        }else{
            localStorage.setItem('number', number);
            localStorage.setItem('name', name);
            localStorage.setItem('Email', email);
            setModal(false)
            setError(false)
            try {
                await axios.post(URI_API, {
                    chat_id: chatId,
                    parse_mode: 'html',
                    text: message
                });
                    // Дополнительный код, который нужно выполнить после успешного запроса
                } catch (error) {
                    // Обработка ошибок, которые могут возникнуть во время запроса
                    console.error('Ошибка при выполнении запроса:', error);
                    // Дополнительный код для обработки ошибок
                }
                // try{
                //     emailjs.send('service_t7lfk5h', 'template_skqvjrj', templateParams, 'BUA2pSs0cilCMXmnH')
                //     .then((response) => {
                //         console.log('Email sent successfully:', response);
                //     }, (error) => {
                //         console.error('Email failed to send:', error);
                //     });
                // }catch{
                //     console.log(error)
                // }
        }
    }

  return (
<motion.section initial={{opacity:0}} whileInView={{opacity:1}} className={css.container} onClick={()=> setModal(false)}>
    <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.modalWind} onClick={e => e.stopPropagation()} >
        <input className={css.input} value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Введите ваш email'/>
        <InputMask className={css.input} value={number} onChange={(e) => setNumber(e.target.value)}  mask="+7 (999) 999 99-99" placeholder='Номер телефона' />
        <input className={css.input} value={name} onChange={(e) => setName(e.target.value)} placeholder='Введите ваше имя'/>
        <div className={css.privateContainer}>
            <div className={css.checkbox} onClick={()=>setCheck(!check)}>
                {check&&<img src={mark}  className={css.mark}/>}
            </div>
            <span>Я согласен с <Link className={css.link} to={'/privatepolicy'}>обработкой персональных данных</Link></span>
        </div>
        <button className={css.button} onClick={() => click()}>Заказать услугу</button>
        {error&&<motion.div initial={{opacity:0}} whileInView={{opacity:1}}  className={css.errorText}>Заполните обязательные поля!</motion.div>}
    </motion.div>
</motion.section>
  )
}

export default Modal