import { useState } from 'react'
import css from './Header.module.css'
import logo from './img/logo.png'
import {Link} from 'react-scroll'
import {motion} from 'framer-motion'


const Header = () => {
  const [modal,setModal] = useState(false)
  return (
<motion.section initial={{y:'-90%'}} whileInView={{y:0}} transition={{duration:.3,ease:'easeOut'}} className={css.container}> 
    <Link smooth={true} offset={0} duration={1500} to="hero"><img src={logo} className={css.logo}/></Link>
    <nav className={`${css.nav} ${modal&& css.navActive}`}>
        <Link spy={true} smooth={true} offset={0} duration={1500} onClick={()=>setModal(false)} to="service" className={css.Link} >Услуги</Link>
        <Link spy={true} smooth={true} offset={-50} duration={1500} onClick={()=>setModal(false)} to="result" className={css.Link} >Результат</Link>
        <Link spy={true} smooth={true} offset={-50} duration={1500} onClick={()=>setModal(false)} to="price" className={css.Link} >Тарифы</Link>
        <Link spy={true} smooth={true} offset={-200} duration={1500} onClick={()=>setModal(false)} to="contacts" className={css.Link} >Контакты</Link>
        <a  href="tel:+79300369460" id='NumberLink' className={`${css.NumberLink}`} >+7(930) 036 94-60</a>
        <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.close} onClick={()=>setModal(!modal)} >x</motion.div>
    </nav>
    <a  href="tel:+79300369460" className={`${css.Link} ${css.NumberMibileLink}`} >+7(930) 036 94-60</a>
    <div className={css.butgerTrigger} onClick={()=>setModal(!modal)}>|||</div>
</motion.section>
  )
}

export default Header