import css from './Price.module.css'
import {motion} from 'framer-motion'
import priceArray from './Price.json'
import { useEffect } from 'react';


const Price = ({setModal2}) => {
    useEffect(() => {
        console.log(window.location.href)
        if (window.location.href.includes('buy')) {
          setModal2(true);
        }
      }, [setModal2]);
  return (
<section className={css.container} id='price'>
    <motion.div viewport={{once:true}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.headerContainer}><h2 className={css.header}>Наши тарифы</h2></motion.div>
    <div className={css.cardContainer}>
        {priceArray.map(card => (
            <div className={css.catdBefore} key={card.header} id={card.id}>
                <div className={card.header === 'Эффективный'? `${css.card} ${css.active}`:css.card}>
                    <div className={css.cardHeaderBox}><h3 className={css.cardHeader}>{card.header}</h3></div>
                    <ul className={css.cardUl}>
                        <li className={css.cardLi}>{card.ul1}</li>
                        <li className={css.cardLi}>{card.ul2}</li>
                        <li className={css.cardLi}>{card.ul3}</li>
                        <li className={css.cardLi}>{card.ul4}</li>
                    </ul>
                    <div className={css.price}>{card.price}</div>
                    <button className={css.button} onClick={()=>setModal2(true)}>Купить</button>
                </div>
            </div>
        ))}
        

    </div>
</section>
  )
}

export default Price