import React, { useEffect, useLayoutEffect, useState } from 'react';
import '../App.css';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Service from '../components/Service/Service';
import Result from '../components/Result/Result';
import Price from '../components/Price/Price';
import Contacts from '../components/Contacts/Contacts';
import Commit from '../components/Commit/Commit';
import Why from '../components/Why/Why';
import Modal from '../components/Modal/Modal';
// import Gsap from '../Gsap';
import Loading from '../components/Loading/Loading';
import Modal2 from '../components/Modal2/Modal2';
import Footer from '../components/Footer/Footer';

const Home = () => {
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [loading,setLoading] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)

    },[2000])
  },[])
  useLayoutEffect(()=>{
      if(window.screen.width>768){
        // Gsap()
      }
  },[loading])
  return (
    <div className="App">
    {!loading?
    <>
    <div className='gradient'></div>
      {modal&&<Modal setModal={setModal}/>}
      {modal2&&<Modal2 setModal={setModal2}/>}
      <Header/>
      <Hero setModal={setModal2}/>
      <Service/>
      <Result/>
      <Price setModal2={setModal2}/>
      <Contacts setModal={setModal2}/>
      <Commit/>
      <Why/>
      <Footer/>
    </>:
    <>
      <Loading/>
    </>}

    </div>
  );
}

export {Home};