import css from './Hero.module.css'
import bacground from './img/bacground.png'
import logo from './img/logo.png'
import logo2 from './img/logo2.png'
import star from './img/star.png'
import {motion} from 'framer-motion'
import map from './img/map.png'

const Hero = ({setModal}) => {
  return (
<section className={css.container} id='hero'>
    <motion.img initial={{opacity:1}} whileInView={{opacity:1}}  transition={{delay:0}} src={bacground} className={css.bacgroundImg}/>
    <motion.img initial={{opacity:0,scale:0}} whileInView={{opacity:1,scale:1}} transition={{delay:.1}} src={map} className={css.map}/>
    <motion.div initial={{opacity:0,scale:1}} whileInView={{opacity:1,scale:1}}  transition={{delay:.4,duration:.7}} className={css.modalContainer}>
        <motion.div  className={css.modalWind}>
                <div className={css.conteiner1}>
                    <div className={css.textContainer}>
                        <div className={css.starContainer}>
                            <span className={css.count}>4.9</span>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                        </div>
                        <div className={css.Header}>
                            <span>Гарантии!</span>
                            <span>Повышение рейтинга.</span>
                            <span>Усиление потока клиентов.</span>       
                        </div>
                    </div>
                    <div className={css.logoContainer}>
                        <img src={logo} className={css.logo}/>
                    </div>
                </div>
                {/* <button className={css.button} onClick={()=>setModal(true)}>Заказать услугу</button> */}
            </motion.div>
    </motion.div>
    <motion.div initial={{opacity:0,scale:1}} whileInView={{opacity:1,scale:1}}  transition={{delay:.8,duration:.7}} className={css.modalContainer2}>
        <motion.div  className={css.modalWind2}>
                <div className={css.conteiner1}>
                    <div className={css.textContainer}>
                        {/* <div className={css.starContainer}>
                            <span className={css.count}>4.9</span>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                            <img className={css.star} src={star}/>
                        </div> */}
                        <div className={css.Header2}>
                            <span>Бесплатный аудит!</span>
                            <span>Закажите прямо сейчас!</span>     
                        </div>
                    </div>
                    <div className={css.logoContainer}>
                        <img src={logo2} className={css.logo}/>
                    </div>
                </div>
                <button className={css.button} onClick={()=>setModal(true)}>Заказать услугу</button>
            </motion.div>
    </motion.div>
   
</section>
  )
}

export default Hero