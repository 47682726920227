import { useEffect, useState } from 'react';
import css from './Modal2.module.css'
import InputMask from 'react-input-mask';
import { motion } from 'framer-motion'
import axios from 'axios';
import mark from '../Modal/img/mark.png'
import { Link } from 'react-router-dom';




// Данные для отправки в телеграмм
const token = process.env.REACT_APP_TOKEN
const chatId = process.env.REACT_APP_CHAT_ID
const URI_API = `https://api.telegram.org/bot${token}/sendMessage`


const Modal2 = ({ setModal }) => {
    const [check, setCheck] = useState(false)
    const [number, setNumber] = useState(localStorage.getItem('number') || '');
    const [name, setName] = useState(localStorage.getItem('name') || '');
    const [name1, setName1] = useState(localStorage.getItem('name1') || '');
    const [adress, setAdress] = useState(localStorage.getItem('adress') || '');
    const [email, setEmail] = useState(localStorage.getItem('email') || '');
    const [error, setError] = useState(false)
    const [faqModal, setFaqModal] = useState(false)
    let message = `<b>Заявка на покупку!</b>\n`



    // const templateParams = {
    //     name: name,
    //     email: 'yoricktomson@gmail.com',
    //     mail:email,
    //     number: number
    // };
    useEffect(() => {
        if (window.screen.width <= 768) {
            document.body.classList.add('popup-open');
        }
        return () => {
            document.body.classList.remove('popup-open');
        }
    }, [])


    const click = async () => {

        if (!name || !email || !number ) {
            setError(true)
        } else {
            if (name) {
                message += `<b>Отправитель: ${name}</b>\n`
                localStorage.setItem('name', name);
            }
            if (number) {
                message += `<b>Номер: ${number}</b>\n`
                localStorage.setItem('number', number);
            }
            message += `<b>Email: ${email}</b>\n`
            localStorage.setItem('email', email);
            message += `<b>Название точки на карте: ${name1}</b>\n`
            localStorage.setItem('name1', name1);
            message += `<b>Адрес: ${adress}</b>\n`
            localStorage.setItem('adress', adress);
            setModal(false)
            setError(false)
            try {
                await axios.post(URI_API, {
                    chat_id: chatId,
                    parse_mode: 'html',
                    text: message
                });
                // Дополнительный код, который нужно выполнить после успешного запроса
            } catch (error) {
                // Обработка ошибок, которые могут возникнуть во время запроса
                console.error('Ошибка при выполнении запроса:', error);
                // Дополнительный код для обработки ошибок
            }
            // try{
            //     emailjs.send('service_t7lfk5h', 'template_skqvjrj', templateParams, 'BUA2pSs0cilCMXmnH')
            //     .then((response) => {
            //         console.log('Email sent successfully:', response);
            //     }, (error) => {
            //         console.error('Email failed to send:', error);
            //     });
            // }catch{
            //     console.log(error)
            // }
        }
    }

    return (
        <motion.section initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className={css.container} onClick={() => setModal(false)}>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className={css.modalWind} onClick={e => e.stopPropagation()} >
                <span className={css.faqTrigger} onMouseEnter={() => setFaqModal(true)} onMouseLeave={() => setFaqModal(false)}>?</span>
                {faqModal &&
                    <motion.span initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className={css.faqModal}>

                        На указанный адрес электронной почты будут отправлены реквизиты для оплаты. После успешного завершения оплаты наш оператор свяжется с вами для координации дальнейших действий.
                    </motion.span>
                }
                <div className={css.inputContainer}>
                    <input className={css.input} value={name} onChange={(e) => setName(e.target.value)} placeholder='Как вас зовут?' />
                    <span>*</span>
                </div>
                <div className={css.inputContainer}>
                     <InputMask className={css.input} value={number} onChange={(e) => setNumber(e.target.value)} mask="+7 (999) 999 99-99" placeholder='Номер телефона' />
                     <span>*</span>
                </div>
                
                <div className={css.inputContainer}>
                    <input
                        className={`${css.input} ${css.star}`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Введите ваш email'
                    />
                    <span>*</span>
                </div>
                <div className={css.inputContainer}>
                    <input
                        className={`${css.input} ${css.star}`}
                        value={name1}
                        onChange={(e) => setName1(e.target.value)}
                        placeholder='Название точки на карте'
                    />
                </div>
                <div className={css.inputContainer}>
                    <input
                        className={`${css.input} ${css.star}`}
                        value={adress}
                        onChange={(e) => setAdress(e.target.value)}
                        placeholder='Адрес'
                    />
                </div>
                <div className={css.privateContainer}>
                    <div className={css.checkbox} onClick={() => setCheck(!check)}>
                        {check && <img src={mark} className={css.mark} />}
                    </div>
                    <span>Я согласен с <Link className={css.link} to={'/privatepolicy'}>обработкой персональных данных</Link></span>
                </div>
                <button className={css.button} onClick={() => click()}>Купить</button>
                {error && <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className={css.errorText}>Заполните обязательные поля!</motion.div>}
            </motion.div>
        </motion.section>
    )
}

export default Modal2