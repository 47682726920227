import css from './Footer.module.css'
import logo from '../Header/img/logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className={css.container}>
        <div className={css.nestContainer}>
        <img src={logo} className={css.logo}/>
        <nav className={css.nav}>

            <span className={css.link}>ИП Новиков Юрий Александрович </span>
            <span className={css.link}>ИНН: 772034536608</span>
            <span className={css.link}>ОГРН/ОГРНИП: 324774600233158</span>
            <Link to={'/privatepolicy'} className={css.link}>Политика конфиденциальности</Link>
        </nav>
        </div>

    </div>
  )
}

export default Footer