import css from './Loading.module.css'

const Loading = () => {
  return (
<section className={css.container}>
<div className={css.wrapper}>
        <div className={css.circle}></div>
        <div className={css.circle}></div>
        <div className={css.circle}></div>
        <div className={css.shadow}></div>
        <div className={css.shadow}></div>
        <div className={css.shadow}></div>
        <span>Loading</span>
    </div>
</section>
  )
}

export default Loading
